<template lang="pug">
	transition(name="slide-fade")
		.home
			loading(:active.sync="busy", :is-full-page="true")
			.widget
				.widget-header
					h1.title IP Info
				.widget-body
					form.form(@submit.prevent="loadData()")
						.form-group(style="max-width: 400px")
							label IPs
								small  (line separated)
							textarea.form-control(rows="6", v-model="filters.ip")
						.form.form-inline.ov-filters
							.form-row-main
								.form-group
									label &nbsp;
									button.btn.btn-primary(type="submit") Submit

					p.clearfix &nbsp;
					p.text-center Showing {{ records.length }} records
					.ov-table-wrapper
						table.table.table-bordered
							thead
								tr
									th IP
									th Country
									th Region
									th City
									th Proxy
							tbody
								tr(v-if="!records.length")
									td(colspan="6") No matching records were found
								tr(v-for="r in records")
									td {{ r.ip }}
									td {{ r.country }}
									td [{{ r.region_id }}] {{ r.region_name }}
									td [{{ r.city_id }}] {{ r.city_name }}
									td {{ r.proxy ? 'Yes' : 'No' }}


</template>
<script>
export default {
  name: 'IPInfo',
  data() {
    return {
      busy: false,
      records: [],
      filters: {
        ip: '',
      },
    };
  },
  methods: {
    async loadData() {
      if (this.busy) {
        return;
      }
      let ip = this.filters.ip;
      let ipList = ip.split('\n');
      let records = [];
      this.busy = true;
      if (ipList.length > 0) {
        let offset = 0;
        let limit = 50;
        while (offset < ipList.length) {
          let slice = ipList.slice(offset, offset + limit);

          let params = {
            ip: slice.join('\n'),
          };
          try {
            let resp = await this.$ovReq.post('location/search', params);
            resp.records.forEach((r) => {
              records.push(r);
            });
          } catch (e) {
            console.error(e);
          }

          offset += limit;
        }

        this.records = records;
      }
      this.busy = false;
    },
  },
  mounted() {},
};
</script>
